import React, { Component } from "react";
import { DatePickerInput } from "rc-datepicker";
import { db } from "./Firebase/firebase";
import TableRow from "./TableRow";
import { withRouter } from "react-router-dom";
import { AppContext } from './context/AppContext';
import { Checkbox } from "flwww";

class Floor extends Component {
  static contextType = AppContext;
  state = {
    reservations: [],
    loading: true,
    date: "",
    dates: [],
    sort: true,
    searchValue: "",
    filter: "timing",
    timeFilter: 0,
    seatFilter: 0,
    showCancelled: true,
  };
  componentDidMount() {
    try {
      this.onDateChange(this.props.location.state.date);
    } catch (e) {
      this.onDateChange(new Date());
    }
    /*
    if (this.props.location.state.date !== undefined) {
      this.onDateChange(this.props.location.state.date);
    } else {
      this.onDateChange(new Date());
    }*/
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    db.collection("notes")
      .doc(this.state.noteId)
      .update({ [e.target.id]: e.target.value });
  };
  onDateChange = (jsDate, dateString) => {
    let newDates = [];
    this.setState(
      {
        date: jsDate,
      },
      () => {
        if (this.state.date !== "") {
          let { date } = this.state;
          let day = date.getDate();
          let month = date.getMonth();
          let year = date.getFullYear();
          db.collection("reservations")
            .where("outlet", "==", this.context.outlet)
            .where("date", ">=", new Date(year, month, day, 0, 0, 0, 0))
            .where("date", "<=", new Date(year, month, day, 23, 0, 0, 0))
            .onSnapshot((querySnapshot) => {
              let data = [];
              querySnapshot.forEach((doc) => {
                let info = doc.data();
                data.push({ ...info, id: doc.id });
              });
              this.setState({
                reservations: data,
                loading: false,
                dates: data,
              });
            });
          db.collection("notes")
            .where("outlet", "==", this.context.outlet)
            .where("date", ">=", new Date(year, month, day, 0, 0, 0, 0))
            .where("date", "<=", new Date(year, month, day, 23, 0, 0, 0))
            .onSnapshot((querySnapshot) => {
              console.log(querySnapshot)
              if (querySnapshot.empty) {
                db.collection("notes").add({
                  createdAt: new Date(),
                  outlet: this.context.outlet,
                  date: jsDate,
                  notes: '',
                })
              }
              else {
                let data = [];
                querySnapshot.forEach((doc) => {
                  let info = doc.data();
                  data.push({ ...info, id: doc.id });
                });
                console.log(data);
                this.setState({
                  notes: data[0].notes,
                  noteId: data[0].id
                });
              }

            });
        }
      }
    );
  };

  /*onDateChange = (jsDate, dateString) => {
    let newDates = [];
    this.setState(
      {
        date: jsDate,
      },
      () => {
        if (this.state.date !== "") {
          let { date } = this.state;
          let calDate;
          let reserveDate;
          calDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
          newDates = this.state.reservations.filter((reserve) => {
            reserveDate = `${reserve.date
              .toDate()
              .getFullYear()}-${reserve.date
              .toDate()
              .getMonth()}-${reserve.date.toDate().getDate()}`;
            return reserveDate === calDate;
          });
          this.setState({ dates: newDates });
        }
      }
    );
  };*/
  sortTime = (dataArray) => {
    let timeAdded = dataArray.map((data) => {
      let date = data.date.toDate();
      let timestring = data.time;
      timestring = timestring.split(":");
      let hour = parseInt(timestring[0]);
      let minute = parseInt(timestring[1]);
      let timeInt = hour * 100 + minute;
      date.setHours(hour, minute);
      let expiry = new Date(date.getTime() + 10 * 60000);
      data.timeNumber = timeInt;
      data.dateTime = expiry;
      return data;
    });

    let timeSorted = timeAdded.sort((a, b) => {
      return a.timeNumber - b.timeNumber;
    });
    let filtered = timeSorted.filter((entry) => {
      return entry.confirmed || entry.declined || entry.cancelled;
    });
    let confirmed = filtered.filter(entry => {
      return !entry.declined;
    })
    return confirmed;
  };
  toggleTimes = () => {
    this.setState({ filter: "timing", timeFilter: this.state.timeFilter === 2 ? 0 : this.state.timeFilter + 1 })
  }
  toggleSeats = () => {
    this.setState({ filter: "seats", seatFilter: this.state.seatFilter === 2 ? 0 : this.state.seatFilter + 1 })
  }
  handleCheck = () => {
    this.setState({ showCancelled: !this.state.showCancelled })
  }
  render() {
    let { date, dates, reservations } = this.state;
    const columns = [
      "Time",
      "Name",
      "Mobile",
      "Pax",
      "Seat",
      "Turnover",
      "TableNo",
    ];

    let times = [...dates];
    let allTimes = this.sortTime(times);
    if (!this.state.showCancelled) {
      allTimes = allTimes.filter(item => {
        return !item.cancelled;
      })
    }
    let timesBefore5 = allTimes.filter((entry) => {
      return entry.timeNumber < 1700;
    });
    let timesAfter5 = allTimes.filter((entry) => {
      return entry.timeNumber > 1700;
    });
    let indoor = allTimes.filter((entry) => {
      return entry.seatPref === 'indoor';
    });
    let outdoor = allTimes.filter((entry) => {
      return entry.seatPref === 'outdoor';
    });
    let filterTimes = [{ filter: "None", data: allTimes }, { filter: "Before 5", data: timesBefore5 }, { filter: "After 5", data: timesAfter5 }];
    let filterSeats = [{ filter: "None", data: allTimes }, { filter: "Indoor", data: indoor }, { filter: "Outdoor", data: outdoor }];
    return (
      <div className="floorplan">
        <div className="today-notes">
          <label htmlFor="notes">Today's Event:</label>
          <textarea name="notes" id="notes" onChange={this.onChange} value={this.state.notes}>{this.state.notes}</textarea>
        </div>
        <div className="floor-bar">
          <div className="dash-bar">
            <div className="dates">
              <DatePickerInput
                value={date}
                onChange={this.onDateChange}
              ></DatePickerInput>
            </div>
          </div>
          <div className="filter-name">
            <h3 style={{ marginLeft: 16, marginRight: 16 }}>Filter - {this.state.filter === "timing" ? filterTimes[this.state.timeFilter].filter : filterSeats[this.state.seatFilter].filter}</h3>
            <Checkbox
              name="checkbox"
              onChange={this.handleCheck}
              checked={this.state.showCancelled}
            ></Checkbox>
            <h3 style={{ marginLeft: 8 }}>Show Cancellations</h3>
          </div>
        </div>
        <div className="floor-table">
          <div className="floor-table-row">
            {columns.map((col) => {
              if (col === 'Time') {
                return <span style={{ fontWeight: "bold", color: "#8d570c" }} onClick={this.toggleTimes}>{col}</span>;
              }
              else if (col === "Seat") {
                return <span style={{ fontWeight: "bold", color: "#8d570c" }} onClick={this.toggleSeats}>{col}</span>;
              }
              else {
                return <span>{col}</span>;
              }
            })}
          </div>
          {this.state.filter === "timing" ? filterTimes[this.state.timeFilter].data.map((data) => {
            return (
              <TableRow
                key={data.id}
                data={data}
                current={new Date()}
              ></TableRow>
            );
          }) : filterSeats[this.state.seatFilter].data.map((data) => {
            return (
              <TableRow
                key={data.id}
                data={data}
                current={new Date()}
              ></TableRow>
            );
          })}
        </div>
      </div>
    );
  }
}
export default withRouter(Floor);
