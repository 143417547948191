import React, { Component, createContext } from "react";
export const AppContext = createContext();

export default class AppContextProvider extends Component {
  state = {
    unaddressed: 0,
    unaddressedToday: 0,
    outlet: "CM-PB",
    admin: false,
    viewer: false,
  };

  setCount = (unaddressed) => {
    this.setState({ unaddressed });
  };
  setCountToday = (unaddressedToday) => {
    this.setState({ unaddressedToday });
  };
  setAdmin = () => {
    this.setState({ admin: true })
  }
  setViewer = () => {
    this.setState({ viewer: true })
  }
  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setCount: this.setCount,
          setCountToday: this.setCountToday,
          setAdmin: this.setAdmin,
          setViewer: this.setViewer
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
