import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import ReserveData from "./ReserveData";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import Switch from "react-switch";
import FilterResults from "react-filter-search";
import { AppContext } from "../context/AppContext";
import { Checkbox } from "flwww";
//import "rc-datepicker/lib/style.css";

export default class Dashboard extends Component {
  static contextType = AppContext;
  state = {
    reservations: [],
    loading: true,
    date: "",
    count: 0,
    dates: [],
    sort: true,
    searchValue: "",
    onlyUnaddressed: false,
  };
  componentDidMount() {
    let day = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    db.collection("reservations")
      .where("outlet", "==", this.context.outlet)
      .where("date", ">=", new Date(year, month, day, 0, 0, 0, 0))
      .orderBy("date", "asc")
      .limit(1000)
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let info = doc.data();
          data.push({ ...info, id: doc.id });
        });
        this.setState(
          {
            reservations: data,
            loading: false,
            dates: data,
            count: data.length,
            unAddressed: data.filter((entry) => {
              return !entry.confirmed && !entry.cancelled && !entry.declined;
            }),
            unAddressedToday: data.filter((entry) => {
              return !entry.confirmed && !entry.cancelled && !entry.declined && entry.date.toDate().getDate() == new Date().getDate();
            }),
          },
          () => {
            this.context.setCount(this.state.unAddressed.length);
            this.context.setCountToday(this.state.unAddressedToday.length);
          }
        );
      });
  }

  onReset = () => {
    this.setState({
      date: "",
      dates: this.state.reservations,
      count: this.state.reservations.length,
    });
  };

  sortTime = (dataArray) => {
    let timeAdded = dataArray.map((data) => {
      let date = data.date.toDate();
      let timestring = data.time;
      timestring = timestring.split(":");
      let hour = parseInt(timestring[0]);
      let minute = parseInt(timestring[1]);
      let timeInt = hour * 100 + minute;
      date.setHours(hour, minute);
      let expiry = new Date(date.getTime() + 10 * 60000);
      data.timeNumber = timeInt;
      data.dateTime = expiry;
      return data;
    });

    let timeSorted = timeAdded.sort((a, b) => {
      return a.timeNumber - b.timeNumber;
    });

    return timeSorted;
  };

  onDateChange = (jsDate, dateString) => {
    let newDates = [];
    this.setState(
      {
        date: jsDate,
      },
      () => {
        if (this.state.date !== "") {
          let { date } = this.state;
          let calDate;
          let reserveDate;
          calDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
          newDates = this.state.reservations.filter((reserve) => {
            reserveDate = `${reserve.date
              .toDate()
              .getFullYear()}-${reserve.date
                .toDate()
                .getMonth()}-${reserve.date.toDate().getDate()}`;
            return reserveDate === calDate;
          });
          this.setState({ dates: newDates, count: newDates.length });
        }
      }
    );
  };
  addCount = () => {
    let current = this.state.count;
    this.setState({
      count: current + 1,
    });
  };

  changeSort = (sort) => {
    this.setState({ sort });
  };
  handleCheck = () => {
    this.setState({ onlyUnaddressed: !this.state.onlyUnaddressed })
  }
  /* {!this.state.loading
          ? this.state.reservations.map(each => {
              if (this.state.date == "") {
                return <ReserveData key={each.id} data={each}></ReserveData>;
              } else {
                let reserveDate = `${each.date
                  .toDate()
                  .getFullYear()}-${each.date
                  .toDate()
                  .getMonth()}-${each.date.toDate().getDate()}`;
                if (reserveDate === calDate) {
                  return <ReserveData key={each.id} data={each}></ReserveData>;
                } else {
                  console.log("fail");
                  return null;
                }
              }
            })
          : null} */
  render() {
    let { date, dates, searchValue, onlyUnaddressed } = this.state;
    let unAddressed = [...dates];
    unAddressed = unAddressed.filter((entry) => {
      return !entry.confirmed && !entry.cancelled && !entry.declined;
    });
    let submissionDates = onlyUnaddressed ? unAddressed : [...dates];
    submissionDates = submissionDates.sort((dateA, dateB) => {
      return dateB.createdAt - dateA.createdAt;
    });
    let sortedTimes = onlyUnaddressed ? unAddressed : [...dates];
    sortedTimes = sortedTimes.map(item => {
      let timestring = item.time;
      timestring = timestring.split(":");
      let hour = parseInt(timestring[0]);
      let minute = parseInt(timestring[1]);
      item.sortDateTime = item.date.toDate();
      item.sortDateTime.setHours(hour, minute);
      console.log(item);
      return item;
    }).sort((a, b) => {
      return a.sortDateTime - b.sortDateTime;
    })
    let calDate;
    if (this.state.date !== "") {
      calDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    }
    return (
      <div className="dashboard">
        <div className="dash-bar">
          <div className="sorts">
            <span style={{ marginRight: 5 }}>Reservation Date</span>
            <Switch
              onChange={this.changeSort}
              checked={this.state.sort}
              onColor="#e6a44a"
              onHandleColor="#f0c892"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
              id="material-switch"
            ></Switch>
            <span style={{ marginLeft: 5 }}>Submission Date</span>
          </div>
          <h3 className="unaddressed">{unAddressed.length}</h3>
          <input
            id="search-bar"
            type="text"
            value={searchValue}
            onChange={(event) => {
              this.setState({ searchValue: event.target.value });
            }}
            placeholder="search"
          />
        </div>
        <div style={{ display: "flex", width: "700px", padding: "8px" }}>
          <label style={{ color: "black", fontWeight: "bold", marginRight: "8px" }} htmlFor="">Only Unaddressed</label>
          <Checkbox
            name="checkbox"
            onChange={this.handleCheck}
            checked={this.state.onlyUnaddressed}
          ></Checkbox>
        </div>
        {/*!this.state.loading
          ? this.state.sort
            ? submissionDates.map((each) => {
                return <ReserveData key={each.id} data={each}></ReserveData>;
              })
            : dates.map((each) => {
                return <ReserveData key={each.id} data={each}></ReserveData>;
              })
          : null */}
        {!this.state.loading ? (
          this.state.sort ? (
            <FilterResults
              value={searchValue}
              data={submissionDates}
              renderResults={(results) => {
                return (
                  <React.Fragment>
                    {results.map((item) => {
                      return (
                        <ReserveData data={item} key={item.id}></ReserveData>
                      );
                    })}
                  </React.Fragment>
                );
              }}
            />
          ) : (
            <FilterResults
              value={searchValue}
              data={sortedTimes}
              renderResults={(results) => {
                return (
                  <React.Fragment>
                    {results.map((item) => {
                      return (
                        <ReserveData data={item} key={item.id}></ReserveData>
                      );
                    })}
                  </React.Fragment>
                );
              }}
            />
          )
        ) : null}
      </div>
    );
  }
}
